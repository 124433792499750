import { UseFormReturn } from 'react-hook-form'
import useJobFilters from '@hooks/useJobFilters'
import useCoursesFilter from '@hooks/useCoursesFilter'
import { CURRENT_STATUS_FILTER } from '@const/filters'
import { FilterTypes } from '@templates/ListPage/Filters/Filter'
import { FiltersProps } from '@templates/ListPage/Filters'
import ASSIGN_TYPES from '@const/assignTypes'

const useEmployeesEducationFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial, division, position } = useJobFilters(methods)
  const courses = useCoursesFilter('course')

  const filters: FiltersProps = [
    {
      filterType: FilterTypes.EMPLOYEE,
      fieldName: 'employees',
      fieldNameForSubmit: 'employeeEducationDestinations.employee',
    },
  ]

  if (company) {
    filters.push(company)
  }

  if (filial) {
    filters.push(filial)
  }

  if (division) {
    filters.push(division)
  }

  if (position) {
    filters.push(position)
  }

  filters.push(
    {
      filterType: FilterTypes.DATE,
      fieldName: 'createdAt[after]',
      placeholder: 'Дата назначения от',
    },
    {
      filterType: FilterTypes.DATE,
      fieldName: 'createdAt[before]',
      placeholder: 'Дата назначения до',
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'type',
      placeholder: 'Тип назначения',
      options: ASSIGN_TYPES,
      withoutFetchOptions: true,
      isMulti: true,
    },
    courses,
    CURRENT_STATUS_FILTER,
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    }
  )

  return filters
}

export default useEmployeesEducationFilters
