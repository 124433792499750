import { UseFormReturn } from 'react-hook-form'
import { SelectProps } from '@organisms/Select'
import { FilterProps, FilterTypes } from '@templates/ListPage/Filters/Filter'
import useJobDataForFields, {
  UseJobDataForFieldsParamsType,
} from './useJobDataForFields'

type Fields = 'company' | 'filial' | 'division' | 'position'

type ReturnType = Record<Fields, FilterProps<SelectProps> | null>

const DROPDOWN_WIDTH = 350

const useJobFilters = (
  methods: UseFormReturn,
  params?: UseJobDataForFieldsParamsType
): ReturnType => {
  const { company, filial, division, position } = useJobDataForFields(
    methods,
    params
  )

  return {
    company: company
      ? {
          ...company,
          placeholder: 'Компания',
          fieldName: 'company',
          filterType: FilterTypes.SELECT_SEARCH,
          fieldNameForSubmit: 'company',
          dropdownWidth: DROPDOWN_WIDTH,
        }
      : null,
    filial: filial
      ? {
          ...filial,
          placeholder: 'Филиал',
          fieldName: 'filial',
          filterType: FilterTypes.SELECT_SEARCH,
          fieldNameForSubmit: 'companyBranch',
          dropdownWidth: DROPDOWN_WIDTH,
        }
      : null,
    division: division
      ? {
          ...division,
          placeholder: 'Подразделение',
          fieldName: 'division',
          filterType: FilterTypes.SELECT_SEARCH,
          fieldNameForSubmit: 'subdivision',
          dropdownWidth: DROPDOWN_WIDTH,
        }
      : null,
    position: position
      ? {
          ...position,
          placeholder: 'Должность',
          fieldName: 'position',
          filterType: FilterTypes.SELECT_SEARCH,
          fieldNameForSubmit: 'jobPosition',
          dropdownWidth: DROPDOWN_WIDTH,
        }
      : null,
  }
}

export default useJobFilters
