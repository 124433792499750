import useDocumentTypes from '@hooks/useDocumentTypes'
import { FiltersProps } from '@templates/ListPage/Filters'

const useLibraryFilters = (): FiltersProps => {
  const documentTypesResponse = useDocumentTypes()
  const { items: documentTypes = [] } = documentTypesResponse.data || {}
  const documentTypesIsLoading = documentTypesResponse.isLoading

  return [
    {
      isLoading: documentTypesIsLoading,
      options: documentTypes,
      placeholder: 'Тип документа',
      isMulti: true,
      fieldName: 'documentType',
      dropdownWidth: 500,
    },
  ]
}

export default useLibraryFilters
