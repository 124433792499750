import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { createInitFilter } from '@utils/filters'
import useSearchParam from '@hooks/useSearchParam'
import DatePicker, { DatePickerProps } from '@organisms/DatePicker'
import { Wrapper } from '@templates/ListPage/Filters/Filter/DateFilter/styled'
import { FilterComponentProps } from '..'

const DateFilter: ForwardRefExoticComponent<
  FilterComponentProps<DatePickerProps> & RefAttributes<typeof DatePicker>
> = forwardRef(
  (
    {
      filter: { fieldName, fieldNameForSubmit, ...rest },
      value = '',
      onFilter,
      field,
    },
    ref
  ) => {
    const { setValue } = useFormContext()
    const param = useSearchParam(fieldName)

    const resultFieldName = fieldNameForSubmit || fieldName

    const handleChange = (value: string | null) => {
      field.onChange?.(value)
      onFilter?.(createInitFilter(resultFieldName, value))
    }

    useEffect(() => {
      if (param) {
        onFilter(createInitFilter(fieldName, param))
      }
    }, [param])

    useEffect(() => {
      if (value) {
        setValue(fieldName, value)
      }
    }, [value])

    return (
      <Wrapper>
        <DatePicker
          {...field}
          {...rest}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          value={isEmpty(field.value) ? '' : field.value}
          style={{ width: 200 }}
          onChange={handleChange}
        />
      </Wrapper>
    )
  }
)

export default DateFilter
