import { FC } from 'react'
import useCompanies from '@hooks/useCompanies'
import ListPage from '@templates/ListPage'
import { CREATED_AT_SORTER } from '@const/sorters'
import CompanyModal from '@modals/CompanyModal'
import { FilterTypes } from '@templates/ListPage/Filters/Filter'
import Company from './Company'

const Companies: FC = () => {
  const { data, ...rest } = useCompanies()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPage
      {...rest}
      filters={[
        {
          filterType: FilterTypes.HIDDEN,
          fieldName: 'id',
        },
        {
          filterType: FilterTypes.HIDDEN,
          fieldName: 'companyGroup',
        },
      ]}
      titleTabs={[
        {
          title: 'Компании',
          counter: length,
          index: 0,
        },
      ]}
      ModalContent={CompanyModal}
      ItemComponent={Company}
      list={items}
      length={length}
      sorters={[CREATED_AT_SORTER]}
      withSearch
    />
  )
}

export default Companies
