import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useToggle } from 'react-use'
import { isMobile } from '@const/device'
import { PER_PAGE } from '@const/pagination'
import useFormProcessor from '@hooks/useFormProcessor'
import { FilterState } from '@hooks/useItemsFilters'
import { OnboardingProps } from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import List from '../List'
import { ReportsProp } from './Header/ReportsButton'
import Header from './Header'
import { TitleProps } from './Header/Title'
import Filters, { FiltersProps } from './Filters'
import { SorterProps, SortStates } from './Filters/Sorter'
import { FilterPanelWrapper } from './styled'

const clearAllFilters = (filters: FilterState): FilterState => {
  const result: FilterState = {}
  for (const key in filters) {
    result[key] = undefined
  }

  return result
}

type Props = {
  methods?: UseFormReturn
  selectedTab?: number
  titleTabs?: Omit<TitleProps, 'onChangeSelected' | 'selected'>[]
  ModalContent?: FC<{ handleCloseModal: () => void }>
  SecondaryModalContent?: FC<{ handleCloseModal: () => void }>
  filters?: FiltersProps
  setFilters?: Dispatch<SetStateAction<FilterState>>
  sorters?: SorterProps[]
  setOrder?: (state: { sortState: SortStates; fieldName: string }) => void
  ItemComponent: FC<any> // correct
  list: Record<string, unknown>[]
  perPage?: number
  isLoading?: boolean
  length: number
  setPage?: (page: number) => void
  perLine?: number
  addButtonTitle?: string
  secondaryAddButtonTitle?: string
  withSearch?: boolean
  additionalPropsForItem?: any // correct
  reports?: ReportsProp
  searchPlaceholder?: string
  initialFiltersState?: any
  reportsOnboarding?: OnboardingProps
  resetOnboardingCodes?: OnboardingCodes[]
}

const ListPage: FC<Props> = ({
  ModalContent,
  SecondaryModalContent,
  selectedTab,
  titleTabs,
  filters = [],
  setFilters,
  ItemComponent,
  list,
  perPage = PER_PAGE,
  perLine = 1,
  isLoading = false,
  length,
  setPage,
  sorters,
  setOrder,
  addButtonTitle,
  secondaryAddButtonTitle,
  withSearch,
  additionalPropsForItem,
  reports,
  methods: methodsProp,
  searchPlaceholder,
  initialFiltersState = {},
  reportsOnboarding,
  resetOnboardingCodes,
}) => {
  const methods = useFormProcessor()
  const { setValue } = methodsProp || methods
  const navigate = useNavigate()

  const [filtersState, setFiltersState] =
    useState<FilterState>(initialFiltersState)

  const handleClearFiltersState = () => {
    setFilters?.(clearAllFilters)
    setFiltersState(clearAllFilters)

    for (const { fieldName, fieldNameForSubmit } of filters) {
      setValue(fieldName, [])

      if (fieldNameForSubmit) {
        setValue(fieldNameForSubmit, [])
      }
    }
  }

  const [refreshStarted, toggleRefreshStarted] = useToggle(false)

  useEffect(() => {
    if (refreshStarted) {
      setTimeout(toggleRefreshStarted, 300)
    }
  }, [refreshStarted, toggleRefreshStarted])

  return (
    <FormProvider {...(methodsProp || methods)}>
      <Header
        ModalContent={ModalContent}
        SecondaryModalContent={SecondaryModalContent}
        selected={selectedTab}
        titleTabs={titleTabs}
        isLoading={isLoading}
        addButtonTitle={addButtonTitle}
        secondaryAddButtonTitle={secondaryAddButtonTitle}
        setFilters={setFilters}
        withSearch={withSearch}
        reports={reports}
        navigate={navigate}
        setFiltersState={setFiltersState}
        searchPlaceholder={searchPlaceholder}
        reportsOnboarding={reportsOnboarding}
        resetOnboardingCodes={resetOnboardingCodes}
        onResetOnboarding={toggleRefreshStarted}
      />
      <FilterPanelWrapper id="filter-panel">
        <Filters
          filters={filters}
          setFilters={setFilters}
          isLoading={isLoading}
          sorters={sorters}
          onSort={setOrder}
          initialFiltersState={initialFiltersState}
          filtersState={filtersState}
          setFiltersState={setFiltersState}
          handleClearFiltersState={handleClearFiltersState}
        />
      </FilterPanelWrapper>
      <List
        list={list}
        perPage={perPage}
        ItemComponent={ItemComponent}
        perLine={isMobile ? 1 : perLine}
        isLoading={refreshStarted || isLoading}
        length={length}
        setPage={setPage}
        additionalPropsForItem={additionalPropsForItem}
        onRefresh={handleClearFiltersState}
      />
    </FormProvider>
  )
}

export default ListPage
