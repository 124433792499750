import { PER_PAGE } from '@const/pagination'
import { useGetEmployeesQuery } from '@services/employees'
import { SortStates } from '@templates/ListPage/Filters/Sorter'
import { createInitFilter } from '@utils/filters'
import useItemsFilters, { FilterState } from './useItemsFilters'

type Params = {
  initFilters?: FilterState
  pagination?: boolean
  limit?: number
  skip?: boolean
  initOrder?: { order: Record<string, SortStates> }
}

const useEmployees = (params?: Params) => {
  const {
    initFilters = {
      ...createInitFilter('active', true),
    },
    pagination = true,
    limit = PER_PAGE,
    skip,
    initOrder,
  } = params || {}
  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit,
    initOrder
  )

  const state = useGetEmployeesQuery(filters, { skip })

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useEmployees
