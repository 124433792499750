import { Dispatch, SetStateAction, useState } from 'react'
import { PER_PAGE } from '@const/pagination'
import { SortStates } from '@templates/ListPage/Filters/Sorter'

export type FilterItem =
  | null
  | string
  | number
  | boolean
  | undefined
  | Array<string | number | boolean | undefined>
  | any
export type FilterState = Record<string, FilterItem>

export type useItemsFiltersReturn = {
  filters: Record<string, unknown>
  setPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<FilterState>>
  setOrder: Dispatch<
    SetStateAction<
      | {
          sortState: SortStates
          fieldName: string
        }
      | undefined
    >
  >
  page: number
}

const useItemsFilters = (
  initFilters: FilterState = {},
  pagination = true,
  limit = PER_PAGE,
  initOrder?: { order: Record<string, SortStates> }
): useItemsFiltersReturn => {
  const [page, setPage] = useState(1)
  const [filtersSrc, setFilters] = useState<FilterState>(initFilters)
  const [order, setOrder] = useState<
    { sortState: SortStates; fieldName: string } | undefined
  >()

  let orderState = initOrder
  if (order) {
    orderState = {
      order: {
        [order.fieldName]: order.sortState,
      },
    }
  }

  const filters: Record<string, unknown> = {
    pagination,
    ...initFilters,
    ...filtersSrc,
  }

  if (pagination) {
    filters.page = page
  }

  if (pagination && limit) {
    filters.limit = limit
  }

  if (orderState) {
    filters.order = orderState
  }

  return {
    filters,
    setPage,
    setFilters,
    setOrder,
    page,
  }
}

export default useItemsFilters
