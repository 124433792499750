import React, { ReactNode } from 'react'
import { ReactComponent as InactiveCurator } from '@main-menu/inactive/curator.svg'
import { ReactComponent as InactiveLibrary } from '@main-menu/inactive/library.svg'
import { ReactComponent as InactiveSettings } from '@main-menu/inactive/settings.svg'
import { ReactComponent as InactiveStatistic } from '@main-menu/inactive/statistic.svg'
import { ReactComponent as InactiveTariff } from '@main-menu/inactive/tariff.svg'
import { ReactComponent as InactiveVitrina } from '@main-menu/inactive/vitrina.svg'
import { ReactComponent as InactiveInstructions } from '@main-menu/inactive/instructions.svg'
import { ReactComponent as ActiveTariff } from '@main-menu/active/tariff.svg'
import { ReactComponent as ActiveStatistic } from '@main-menu/active/statistic.svg'
import { ReactComponent as ActiveSettings } from '@main-menu/active/settings.svg'
import { ReactComponent as ActiveLibrary } from '@main-menu/active/library.svg'
import { ReactComponent as ActiveCurator } from '@main-menu/active/curator.svg'
import { ReactComponent as ActiveVitrina } from '@main-menu/active/vitrina.svg'
import { ReactComponent as ActiveInstructions } from '@main-menu/active/instructions.svg'
import { ReactComponent as ActiveRisks } from '@main-menu/active/risks.svg'
import { ReactComponent as InactiveRisks } from '@main-menu/inactive/risks.svg'
import { Roles } from '@enums/Roles'
import { Routes } from '@enums/Routes'
import { Icon } from '@app-types/common'
import Certificates from '@pages/Certificates'
import Courses from '@pages/Courses'
import Curator from '@pages/Curator'
import Init from '@pages/Init'
import Library from '@pages/Library'
import Profile from '@pages/Profile'
import Settings from '@pages/Settings'
import Statistic from '@pages/Statistic'
import Study from '@pages/Study'
import Tariff from '@pages/Tariff'
import Theme from '@pages/Theme'
import Themes from '@pages/Themes'
import Course from '@pages/Course'
import EnrollmentsHistory from '@pages/EnrollmentsHistory'
import PrivacyPolicy from '@pages/PrivacyPolicy'
import Positions from '@pages/Positions'
import Filials from '@pages/Filials'
import Divisions from '@pages/Divisions'
import Companies from '@pages/Companies'
import Employees from '@pages/Employees'
import EmployeesEducation from '@pages/EmployeesEducation'
import FilialSettings from '@pages/FilialSettings'
import CompanyModalOnRoute from '@modals/CompanyModalOnRoute'
import FilialModalOnRoute from '@modals/FilialModalOnRoute'
import DivisionModalOnRoute from '@modals/DivisionModalOnRoute'
import PositionModalOnRoute from '@modals/PositionModalOnRoute'
import EmployeeModalOnRoute from '@modals/EmployeeModalOnRoute'
import CompanySettings from '@pages/CompanySettings'
import RiskMaps from '@pages/RiskMaps'
import Instructions from '@pages/Instructions'
import RiskMapsEmployee from '@pages/RiskMapsEmployee'
import Instruction from '@pages/Instruction'
import { Me } from '@models/me'
import DivisionSettings from '@pages/DivisionSettings'
import CompanyGroups from '@pages/CompanyGroups'
import CompanyGroupsCuratorTariff from '@pages/CompanyGroupsCuratorTariff'
import {
  ACK_WITH_RISK_MAPS,
  ACK_WITH_RISK_MAPS_REPORT,
  ANTI_FIRE_REPORT,
  ANTI_FIRE_REPORT_NAME,
  BODY_CHECK_REPORT,
  BODY_CHECK_REPORT_NAME,
  DANGEROUS_REGISTRY,
  DANGEROUS_REGISTRY_REPORT,
  ELECTRO_SAFE_REPORT,
  ELECTRO_SAFE_REPORT_NAME,
  EMPLOYEES_EDUCATION_STATS_REPORT,
  EMPLOYEES_EDUCATION_STATS_REPORT_NAME,
  INITIAL_BRIEFING_REPORT,
  INITIAL_BRIEFING_REPORT_NAME,
  LINKS_REPORT,
  OPENED_REPORT_QUERY,
  OTHER_BRIEFING_REPORT,
  OTHER_BRIEFING_REPORT_NAME,
  POSITIONS_REPORT,
  POSITIONS_REPORT_NAME,
  POSITIONS_WITHOUT_RISK_MAPS,
  POSITIONS_WITHOUT_RISK_MAPS_REPORT,
  RISK_MANAGEMENT_PLAN,
  RISK_MANAGEMENT_PLAN_REPORT,
  RISK_MANAGEMENT_SYSTEM,
  RISK_MANAGEMENT_SYSTEM_REPORT,
} from '@const/reportNames'
// import NotFound from '@pages/NotFound'

export type RouteConfig = {
  ActiveIcon?: Icon
  InactiveIcon?: Icon
  text?: string
  getText?: (me: Me) => string
  route: Routes
  routesForMatch?: Routes[]
  onItemClick?: (route: string) => void
  roles?: Set<Roles>
  element?: ReactNode
  hidden?: boolean
  badgeText?: string
  query?: string
}

const ROUTES_CONFIG: RouteConfig[] = [
  {
    text: 'Группы компаний',
    route: Routes.COMPANY_GROUPS,
    roles: new Set([Roles.ADMIN]),
    element: <CompanyGroups />,
  },
  {
    text: 'Компании',
    route: Routes.COMPANIES,
    roles: new Set([Roles.ADMIN, Roles.COMPANY_GROUP_CURATOR]),
    element: <Companies />,
  },
  {
    text: 'Филиалы',
    route: Routes.FILIALS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
    ]),
    element: <Filials />,
  },
  {
    text: 'Подразделения',
    route: Routes.DIVISIONS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
    ]),
    element: <Divisions />,
  },
  {
    text: 'Должности',
    route: Routes.POSITIONS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Positions />,
  },
  {
    text: 'Сотрудники',
    route: Routes.EMPLOYEES,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Employees />,
  },
  {
    text: BODY_CHECK_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${BODY_CHECK_REPORT}`,
    route: Routes.EMPLOYEES,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: 'Ссылки для задания паролей сотрудникам',
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${LINKS_REPORT}`,
    route: Routes.EMPLOYEES,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: 'Обучение сотрудников',
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <EmployeesEducation />,
  },
  {
    text: 'Мое обучение',
    route: Routes.STUDY,
    roles: new Set([
      Roles.EMPLOYEE,
      Roles.FILIAL_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Study />,
  },
  {
    text: 'Мои сертификаты',
    route: Routes.CERTIFICATES,
    roles: new Set([
      Roles.EMPLOYEE,
      Roles.FILIAL_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Certificates />,
  },
  {
    text: EMPLOYEES_EDUCATION_STATS_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${EMPLOYEES_EDUCATION_STATS_REPORT}`,
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: INITIAL_BRIEFING_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${INITIAL_BRIEFING_REPORT}`,
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: OTHER_BRIEFING_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${OTHER_BRIEFING_REPORT}`,
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: ANTI_FIRE_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${ANTI_FIRE_REPORT}`,
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: ELECTRO_SAFE_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${ELECTRO_SAFE_REPORT}`,
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: POSITIONS_REPORT_NAME,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${POSITIONS_REPORT}`,
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    ActiveIcon: ActiveVitrina,
    InactiveIcon: InactiveVitrina,
    text: 'Витрина курсов',
    route: Routes.COURSES,
    routesForMatch: [Routes.THEMES, Routes.COURSE, Routes.THEME],
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
      Roles.EMPLOYEE,
    ]),
    element: <Courses />,
  },
  {
    ActiveIcon: ActiveStatistic,
    InactiveIcon: InactiveStatistic,
    text: 'Статистика',
    route: Routes.STATISTIC,
    roles: new Set([
      Roles.EMPLOYEE,
      Roles.DIVISION_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
    ]),
    element: <Statistic />,
  },
  {
    text: 'Карты рисков',
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <RiskMaps />,
  },
  {
    ActiveIcon: ActiveRisks,
    InactiveIcon: InactiveRisks,
    getText: (me) =>
      me.isEmployee ? 'Карты рисков' : 'Карты рисков (ознакомление)',
    route: Routes.RISKS_MAPS_REVIEW,
    roles: new Set([Roles.EMPLOYEE]),
    element: <RiskMapsEmployee />,
  },
  // {
  //   ActiveIcon: ActiveRisks,
  //   InactiveIcon: InactiveRisks,
  //   text: 'Карты CОУТ',
  //   route: Routes.SOUT_MAPS,
  //   roles: new Set([
  //     Roles.ADMIN,
  //     Roles.COMPANY_GROUP_CURATOR,
  //     Roles.COMPANY_CURATOR,
  //     Roles.FILIAL_CURATOR,
  //     Roles.DIVISION_CURATOR,
  //     Roles.EMPLOYEE,
  //   ]),
  //   element: <NotFound />,
  // },
  // {
  //   text: 'Рабочие места',
  //   route: Routes.WORK_PLACES,
  //   roles: new Set([
  //     Roles.ADMIN,
  //     Roles.COMPANY_GROUP_CURATOR,
  //     Roles.COMPANY_CURATOR,
  //     Roles.FILIAL_CURATOR,
  //     Roles.DIVISION_CURATOR,
  //   ]),
  //   element: <NotFound />,
  // },
  {
    text: DANGEROUS_REGISTRY,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${DANGEROUS_REGISTRY_REPORT}`,
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: RISK_MANAGEMENT_PLAN,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${RISK_MANAGEMENT_PLAN_REPORT}`,
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: ACK_WITH_RISK_MAPS,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${ACK_WITH_RISK_MAPS_REPORT}`,
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: POSITIONS_WITHOUT_RISK_MAPS,
    badgeText: 'xls',
    query: `?${OPENED_REPORT_QUERY}=${POSITIONS_WITHOUT_RISK_MAPS_REPORT}`,
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    text: RISK_MANAGEMENT_SYSTEM,
    badgeText: 'doc',
    query: `?${OPENED_REPORT_QUERY}=${RISK_MANAGEMENT_SYSTEM_REPORT}`,
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    ActiveIcon: ActiveTariff,
    InactiveIcon: InactiveTariff,
    text: 'Тариф',
    route: Routes.TARIFF,
    routesForMatch: [Routes.ENROLLMENT_HISTORY],
    roles: new Set([Roles.COMPANY_CURATOR, Roles.FILIAL_CURATOR]),
    element: <Tariff />,
  },
  {
    ActiveIcon: ActiveTariff,
    InactiveIcon: InactiveTariff,
    text: 'Тариф',
    route: Routes.TARIFF,
    routesForMatch: [Routes.ENROLLMENT_HISTORY],
    roles: new Set([Roles.COMPANY_GROUP_CURATOR]),
    element: <CompanyGroupsCuratorTariff />,
  },
  {
    route: Routes.THEMES,
    element: <Themes />,
  },
  {
    ActiveIcon: ActiveLibrary,
    InactiveIcon: InactiveLibrary,
    text: 'Библиотека',
    route: Routes.LIBRARY,
    element: <Library />,
  },
  {
    ActiveIcon: ActiveCurator,
    InactiveIcon: InactiveCurator,
    text: 'Куратор',
    route: Routes.CURATOR,
    roles: new Set([Roles.EMPLOYEE]),
    element: <Curator />,
  },
  {
    ActiveIcon: ActiveSettings,
    InactiveIcon: InactiveSettings,
    text: 'Настройки портала',
    route: Routes.SETTINGS,
    roles: new Set([Roles.ADMIN]),
    element: <Settings />,
  },
  {
    ActiveIcon: ActiveSettings,
    InactiveIcon: InactiveSettings,
    text: 'Настройки компании',
    route: Routes.COMPANY_SETTINGS,
    roles: new Set([Roles.COMPANY_CURATOR]),
    element: <CompanySettings />,
  },
  {
    ActiveIcon: ActiveSettings,
    InactiveIcon: InactiveSettings,
    text: 'Настройки филиала',
    route: Routes.FILIAL_SETTINGS,
    roles: new Set([Roles.FILIAL_CURATOR]),
    element: <FilialSettings />,
  },
  {
    ActiveIcon: ActiveSettings,
    InactiveIcon: InactiveSettings,
    text: 'Настройки подразделения',
    route: Routes.DIVISION_SETTINGS,
    roles: new Set([Roles.DIVISION_CURATOR]),
    element: <DivisionSettings />,
  },
  {
    text: 'Инструкции',
    route: Routes.GUIDES,
    ActiveIcon: ActiveInstructions,
    InactiveIcon: InactiveInstructions,
    element: <Instructions />,
  },
  {
    text: 'Init',
    route: Routes.INIT,
    element: <Init />,
    hidden: true,
  },
  {
    route: Routes.ENROLLMENT_HISTORY,
    roles: new Set([
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <EnrollmentsHistory />,
  },
  {
    route: Routes.PROFILE,
    element: <Profile />,
  },
  {
    route: Routes.THEME,
    element: <Theme />,
  },
  {
    route: Routes.COURSE,
    element: <Course />,
  },
  {
    route: Routes.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    route: Routes.GUIDE,
    element: <Instruction />,
  },
  {
    route: Routes.COMPANY_MODAL,
    element: <CompanyModalOnRoute />,
    roles: new Set([Roles.ADMIN, Roles.COMPANY_GROUP_CURATOR]),
  },
  {
    route: Routes.FILIAL_MODAL,
    element: <FilialModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
    ]),
  },
  {
    route: Routes.DIVISION_MODAL,
    element: <DivisionModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
    ]),
  },
  {
    route: Routes.POSITION_MODAL,
    element: <PositionModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    route: Routes.EMPLOYEE_MODAL,
    element: <EmployeeModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
]

export default ROUTES_CONFIG
