import { Dispatch, SetStateAction } from 'react'
import { PER_PAGE } from '@const/pagination'
import { SortStates } from '@templates/ListPage/Filters/Sorter'
import { useGetDangerHazardsQuery } from '@services/dangerHazards'
import { StateType } from '@modals/RiskMapModal/Dangers/useCommonRiskMapFields'
import useItemsFilters from './useItemsFilters'

type Params = {
  initFilters?: StateType
  pagination?: boolean
  limit?: number
  skip?: boolean
  initOrder?: { order: Record<string, SortStates> }
}

const useDangerHazards = (params?: Params) => {
  const {
    initFilters = {},
    pagination = true,
    limit = PER_PAGE,
    initOrder,
    skip,
  } = params || {}
  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit,
    initOrder
  )

  const state = useGetDangerHazardsQuery(filters, { skip })

  return {
    ...state,
    setPage,
    setFilters: setFilters as Dispatch<SetStateAction<StateType>>,
    setOrder,
  }
}

export default useDangerHazards
