import { useGetScormsQuery } from '@services/scorms'
import { SortStates } from '@templates/ListPage/Filters/Sorter'
import useItemsFilters, { FilterState } from './useItemsFilters'

type Params = {
  initFilters?: FilterState
  pagination?: boolean
  limit?: number
  skip?: boolean
  initOrder?: { order: Record<string, SortStates> }
}

const useScorms = (params?: Params) => {
  const {
    initFilters = {},
    pagination = false,
    limit,
    skip,
    initOrder,
  } = params || {}

  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit,
    initOrder
  )

  const state = useGetScormsQuery(filters, {
    skip,
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  })

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useScorms
