import { useGetDocumentsQuery } from '@services/documents'
import { SortStates } from '@templates/ListPage/Filters/Sorter'
import useItemsFilters, { FilterState } from './useItemsFilters'

type Params = {
  initFilters?: FilterState
  pagination?: boolean
  limit?: number
  skip?: boolean
  initOrder?: { order: Record<string, SortStates> }
}

const useDocuments = (params?: Params) => {
  const {
    initFilters = {},
    pagination = false,
    limit,
    skip,
    initOrder,
  } = params || {}
  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit,
    initOrder
  )

  const state = useGetDocumentsQuery(filters, { skip })

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useDocuments
