import { FC, useEffect } from 'react'
import { FilterComponentProps } from '@templates/ListPage/Filters/Filter'
import { createInitFilter } from '@utils/filters'
import { Option } from '@organisms/Select'
import useSearchParam from '@hooks/useSearchParam'

const HiddenFilter: FC<FilterComponentProps<null>> = ({
  filter,
  field,
  onFilter,
}) => {
  const { fieldName, fieldNameForSubmit } = filter
  const param = useSearchParam(fieldName)

  const resultFieldName = fieldNameForSubmit || fieldName

  const handleSelect = (values: Option[]) => {
    field.onChange?.(values)

    if (values.length === 1) {
      onFilter({
        [resultFieldName]: values[0]?.value,
      })
    } else {
      onFilter(
        createInitFilter(
          resultFieldName,
          values.map((value) => value.value)
        )
      )
    }
  }

  useEffect(() => {
    if (param) {
      const items = param.split(',')
      handleSelect(
        items.map((item) => ({
          value: item,
          label: '',
        }))
      )
    }
  }, [param])

  return null
}

export default HiddenFilter
