import * as React from 'react'
import { FC, useEffect } from 'react'
import { notification } from 'antd'
import GrayButton from '@organisms/GrayButton'
import { useSendForReviewMutation } from '@services/riskMaps'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import { RiskMapDetailed } from '@models/riskMap'
import Prompt from '@organisms/Prompt'
import ButtonWithConfirm from '@templates/ButtonWithConfirm'
import { Wrapper } from './styled'

const NOT_ACTIVE_STATUSES = new Set([
  RiskMapStatuses.CANCELED,
  RiskMapStatuses.DRAFT,
  RiskMapStatuses.ARCHIVED,
])

type Props = {
  data: RiskMapDetailed
  index: number
}

const SendForReview: FC<Props> = ({ data, index }) => {
  const {
    status,
    id,
    file,
    lastSendForReviewRevision,
    availableToSendForReview,
  } = data || {}

  const [sendForReview, { isLoading, isSuccess }] = useSendForReviewMutation()

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Карта рисков отправлена на ознакомление',
      })
    }
  }, [isSuccess])

  const handleClick = () => {
    void sendForReview(id)
  }

  const notActive = NOT_ACTIVE_STATUSES.has(status)
  const fileNotLoaded = !file
  const riskMapSent =
    !!lastSendForReviewRevision && status !== RiskMapStatuses.CHANGED
  const extended =
    status === RiskMapStatuses.EXTENDED && !!lastSendForReviewRevision

  const isSent =
    (!availableToSendForReview || extended || riskMapSent) &&
    status !== RiskMapStatuses.DRAFT

  return (
    <Wrapper id={`risk-map-send-for-review-${index}`}>
      <ButtonWithConfirm
        ButtonComponent={({ onClick }) => (
          <GrayButton
            onClick={onClick}
            isLoading={isLoading}
            disabled={notActive || isSent}
          >
            Отправить на ознакомление
          </GrayButton>
        )}
        onConfirm={handleClick}
        confirmText="Сотрудники получат доступ к карте рисков и уведомление о необходимости ознакомления. Продолжить?"
        confirmButtonText="Продолжить"
        cancelText="Отменить"
      />
      {notActive && (
        <Prompt promptText="В текущем статусе нельзя отправить карту на ознакомление" />
      )}
      {isSent && <Prompt promptText="Карта рисков уже отправлена" />}
      {fileNotLoaded && <Prompt promptText="Не загружен файл карты рисков" />}
    </Wrapper>
  )
}

export default SendForReview
