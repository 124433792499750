import { useState } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { ReportsProp } from '@templates/ListPage/Header/ReportsButton'
import {
  ANTI_FIRE_REPORT_NAME,
  ELECTRO_SAFE_REPORT_NAME,
  EMPLOYEES_EDUCATION_STATS_REPORT_NAME,
  INITIAL_BRIEFING_REPORT_NAME,
  OTHER_BRIEFING_REPORT_NAME,
  POSITIONS_REPORT_NAME,
  EMPLOYEES_EDUCATION_STATS_REPORT,
  INITIAL_BRIEFING_REPORT,
  OTHER_BRIEFING_REPORT,
  ANTI_FIRE_REPORT,
  ELECTRO_SAFE_REPORT,
  POSITIONS_REPORT,
} from '@const/reportNames'
import {
  useLazyGetElectricalSafetyReportQuery,
  useLazyGetEmployeesInitialBriefingReportQuery,
  useLazyGetEmployeesOtherBriefingReportQuery,
  useLazyGetFireSafetyReportQuery,
} from '@services/employeeEducation'
import { Option } from '@organisms/Select'
import { CompanyDetailed } from '@models/company'
import { DivisionDetailed } from '@models/division'
import { FilialDetailed } from '@models/filial'
import { PositionDetailed } from '@models/position'
import { User } from '@models/user'
import { useLazyGetEmployeesReportQuery } from '@services/users'
import { ProgressStatuses } from '@enums/ProgressStatuses'
import { CourseDetailed } from '@models/course'
import { useLazyGetPositionsReportQuery } from '@services/employees'
import useEmployeesEducationsFiltersModalFields from './useEmployeesEducationsFiltersModalFields'

export enum EducationReports {
  EMPLOYEES_REPORT,
  INITIAL_BRIEFING,
  OTHER_BRIEFING,
  FIRE_SAFETY,
  ELECTRICAL_SAFETY,
  POSITIONS_REPORT_NAME,
}

type FormValues = {
  company?: Option & CompanyDetailed
  position?: Option & PositionDetailed
  division?: Option & DivisionDetailed
  filial?: Option & FilialDetailed
  conductsBriefing?: Option & User
  interval?: Option
  year?: Option
  before?: string
  after?: string
  date?: string
  course?: Option & CourseDetailed
  passedCourseFrom?: string
  passedCourseTo?: string
  uncompleted?: boolean
}

type FormFiltersOne = {
  companyBranch: string[] | string | undefined
  company: string[] | string | undefined
  subdivision: string[] | string | undefined
  jobPosition: string[] | string | undefined
  conductsBriefing: string[] | string | undefined
  'passedAt[before]': string | undefined
  'passedAt[after]': string | undefined
}

type FormFiltersTwo = {
  companyBranch: string[] | string | undefined
  'companyBranch.company': string[] | string | undefined
  'subdivisionJobPositionRels.subdivision': string[] | string | undefined
  'subdivisionJobPositionRels.jobPosition': string[] | string | undefined
  'employeeEducationDestinations.educationDestination.conductsBriefing':
    | string[]
    | string
    | undefined
  'employeeEducationDestinations.passedAt[before]': string | undefined
  'employeeEducationDestinations.passedAt[after]': string | undefined
}

type FormFiltersThree = {
  company: string | undefined
  companyBranch: string | undefined
  subdivision: string | undefined
  jobPosition: string | undefined
  'passedAt[before]': string | undefined
  'passedAt[after]': string | undefined
  courseIncomplete: string | undefined
  courses: string | undefined
}

const useEmployeesEducationReports = (): ReportsProp => {
  const [selectedReport, setSelectedReport] = useState<
    EducationReports | undefined
  >()

  const methods = useFormProcessor()
  const filterModalFields = useEmployeesEducationsFiltersModalFields(
    selectedReport,
    methods
  )

  const uncompleted = methods.watch('uncompleted')

  const [
    getEmployeesReportQuery,
    { isLoading: getEmployeesReportQueryIsLoading },
  ] = useLazyGetEmployeesReportQuery()

  const [
    getInitialBriefingReport,
    { isLoading: getInitialBriefingReportIsLoading },
  ] = useLazyGetEmployeesInitialBriefingReportQuery()

  const [
    getOtherBriefingReport,
    { isLoading: getOtherBriefingReportIsLoading },
  ] = useLazyGetEmployeesOtherBriefingReportQuery()

  const [getFireSafetyReport, { isLoading: getFireSafetyReportIsLoading }] =
    useLazyGetFireSafetyReportQuery()

  const [
    getElectricalSafetyReport,
    { isLoading: getElectricalSafetyReportIsLoading },
  ] = useLazyGetElectricalSafetyReportQuery()

  const [
    getPositionsReportQuery,
    { isLoading: getPositionsReportQueryIsLoading },
  ] = useLazyGetPositionsReportQuery()

  const transformFormValues = (
    form: FormValues
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): FormFiltersOne | FormFiltersTwo | FormFiltersThree => {
    let before: string | undefined
    let after: string | undefined
    const interval = form.interval?.value

    if (interval) {
      switch (interval) {
        case 'year': {
          if (form.year?.value) {
            before = `31.12.${form.year.value}`
            after = `01.01.${form.year.value}`
          }

          break
        }
        case 'period': {
          if (form.before) {
            before = form.before + 'T23:59:59'
          }

          if (form.after) {
            after = form.after + 'T00:00:00'
          }

          break
        }
        case 'date': {
          if (form.date) {
            after = form.date + 'T00:00:00'
            before = form.date + 'T23:59:59'
          }

          break
        }
        // No default
      }
    }

    switch (selectedReport) {
      case EducationReports.EMPLOYEES_REPORT:
        return {
          courses: form.course?.['@id'],
          company: form.company?.['@id'],
          companyBranch: form.filial?.['@id'],
          subdivision: form.division?.['@id'],
          jobPosition: form.position?.['@id'],
          'passedAt[after]': form.passedCourseTo,
          'passedAt[before]': form.passedCourseFrom,
          courseIncomplete: uncompleted
            ? [ProgressStatuses.NOT_STARTED, ProgressStatuses.IN_PROCESS]
            : undefined,
        } as FormFiltersThree
      case EducationReports.FIRE_SAFETY:
      case EducationReports.ELECTRICAL_SAFETY:
        return {
          conductsBriefing: form.conductsBriefing?.['@id'],
          company: form.company?.['@id'],
          companyBranch: form.filial?.['@id'],
          subdivision: form.division?.['@id'],
          jobPosition: form.position?.['@id'],
          'passedAt[before]': before,
          'passedAt[after]': after,
        }
      case EducationReports.INITIAL_BRIEFING:
      case EducationReports.OTHER_BRIEFING:
      default:
        return {
          companyBranch: form.filial?.['@id'],
          'employeeEducationDestinations.educationDestination.conductsBriefing':
            form.conductsBriefing?.['@id'],
          'companyBranch.company': form.company?.['@id'],
          'subdivisionJobPositionRels.subdivision': form.division?.['@id'],
          'subdivisionJobPositionRels.jobPosition': form.position?.['@id'],
          'employeeEducationDestinations.passedAt[before]': before,
          'employeeEducationDestinations.passedAt[after]': after,
        }
    }
  }

  return {
    transformFormValues,
    filterModalFields,
    methods,
    onSelectReport: setSelectedReport,
    items: [
      {
        title: EMPLOYEES_EDUCATION_STATS_REPORT_NAME,
        isLoading: getEmployeesReportQueryIsLoading,
        onClick: getEmployeesReportQuery,
        id: EMPLOYEES_EDUCATION_STATS_REPORT,
      },
      {
        title: INITIAL_BRIEFING_REPORT_NAME,
        isLoading: getInitialBriefingReportIsLoading,
        onClick: getInitialBriefingReport,
        id: INITIAL_BRIEFING_REPORT,
      },
      {
        title: OTHER_BRIEFING_REPORT_NAME,
        isLoading: getOtherBriefingReportIsLoading,
        onClick: getOtherBriefingReport,
        id: OTHER_BRIEFING_REPORT,
      },
      {
        title: ANTI_FIRE_REPORT_NAME,
        isLoading: getFireSafetyReportIsLoading,
        onClick: getFireSafetyReport,
        id: ANTI_FIRE_REPORT,
      },
      {
        title: ELECTRO_SAFE_REPORT_NAME,
        isLoading: getElectricalSafetyReportIsLoading,
        onClick: getElectricalSafetyReport,
        id: ELECTRO_SAFE_REPORT,
      },
      {
        title: POSITIONS_REPORT_NAME,
        isLoading: getPositionsReportQueryIsLoading,
        onClick: getPositionsReportQuery,
        id: POSITIONS_REPORT,
      },
    ],
  }
}

export default useEmployeesEducationReports
