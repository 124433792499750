import { FC, useState } from 'react'
import { SecondaryButtonProps } from '@organisms/SecondaryButton'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import WarningButton from '@organisms/WarningButton'
import { Wrapper } from '@templates/ListPage/Filters/Sorter/styled'
import Skeleton from '../Skeleton'

export enum SortStates {
  INIT = 'init',
  ASC = 'asc',
  DESC = 'desc',
}

const STATES_CONFIG = {
  [SortStates.ASC]: SortStates.DESC,
  [SortStates.DESC]: SortStates.INIT,
  [SortStates.INIT]: SortStates.ASC,
}

type Props = {
  title: string
  fieldName: string
  onSort?: (state: { sortState: SortStates; fieldName: string }) => void
  initState?: SortStates
} & SecondaryButtonProps

const Sorter: FC<Props> = ({
  onSort,
  fieldName,
  title,
  isLoading,
  initState = SortStates.INIT,
  ...restProps
}) => {
  const [sortState, setSortState] = useState(initState)

  const handleClick = () => {
    setSortState((prevState) => {
      const nextState = STATES_CONFIG[prevState]

      onSort?.({
        sortState: nextState,
        fieldName,
      })

      return nextState
    })
  }

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Wrapper>
      <WarningButton
        {...restProps}
        isActive={sortState !== SortStates.INIT}
        withoutUpperCase
        onClick={handleClick}
        leftIcon={
          sortState === SortStates.INIT ? undefined : sortState ===
            SortStates.DESC ? (
            <DownOutlined />
          ) : (
            <UpOutlined />
          )
        }
      >
        {title}
      </WarningButton>
    </Wrapper>
  )
}

export type SorterProps = Omit<Props, 'onSort'>
export default Sorter
