import { useState } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { ReportsProp } from '@templates/ListPage/Header/ReportsButton'
import {
  ACK_WITH_RISK_MAPS,
  ACK_WITH_RISK_MAPS_REPORT,
  DANGEROUS_REGISTRY,
  DANGEROUS_REGISTRY_REPORT,
  POSITIONS_WITHOUT_RISK_MAPS,
  POSITIONS_WITHOUT_RISK_MAPS_REPORT,
  RISK_MANAGEMENT_PLAN,
  RISK_MANAGEMENT_PLAN_REPORT,
  RISK_MANAGEMENT_SYSTEM,
  RISK_MANAGEMENT_SYSTEM_REPORT,
  SIZ,
} from '@const/reportNames'
import {
  useLazyGetPositionWithoutRiskMapReportQuery,
  useLazyGetProfRiskManagementSystemReportQuery,
  useLazyGetRiskMapControlMeasurePlanReportQuery,
  useLazyGetRiskMapDangerReportQuery,
  useLazyGetRiskMapEmployeeReviewReportQuery,
  useLazyGetSizReportQuery,
} from '@services/riskMaps'
import { CompanyOption } from '@models/company'
import { DivisionOption } from '@models/division'
import { FilialOption } from '@models/filial'
import { PositionOption } from '@models/position'
import { Option } from '@organs/Select'
import useMe from '@hooks/useMe'
import { REVIEW_STATUS_OPTIONS, ReviewStatuses } from '@pages/RiskMaps/const'
import { RiskMapOption } from '@models/riskMap'
import useHasJobs from '@hooks/useHasJobs'
import useRiskMapsFiltersModalFields from './useRiskMapsFiltersModalFields'
import { RiskMapReports } from './enums'

const ReviewStatusesToApi = {
  [ReviewStatuses.REVIEWED]: true,
  [ReviewStatuses.NOT_REVIEWED]: false,
  [ReviewStatuses.ALL]: undefined,
}

type FormValues = {
  company?: CompanyOption
  division?: DivisionOption
  filial?: FilialOption
  position?: PositionOption
  reviewedBeforeAt?: string
  reviewStatus?: Option<ReviewStatuses>
  riskMap?: RiskMapOption
  headPosition?: string
  safetyDivision?: string
}

type FormFilters = {
  company: string | undefined
  companyBranch: string | undefined
  branchSubdivision: string | undefined
  jobPosition: string | undefined
  reviewedBeforeAt: string | undefined
  reviewStatus: boolean | undefined
  riskMap: string | undefined
  riskAssessmentJobPosition: string | undefined
  laborProtectionService: string | undefined
}

const useRiskMapsReports = (): ReportsProp => {
  const { hasCompany, hasFilial } = useHasJobs()
  const { company: meCompany, companyBranch: meCompanyBranch } = useMe()
  const [selectedReport, setSelectedReport] = useState(
    RiskMapReports.DANGEROUS_REGISTRY
  )

  const methods = useFormProcessor({
    defaultValues: {
      reviewStatus: REVIEW_STATUS_OPTIONS.find(
        ({ value }) => value === ReviewStatuses.ALL
      ),
    },
  })
  const filterModalFields = useRiskMapsFiltersModalFields(
    methods,
    selectedReport
  )

  const [
    getPositionWithoutRiskMapReportQuery,
    { isLoading: getPositionWithoutRiskMapReportQueryIsLoading },
  ] = useLazyGetPositionWithoutRiskMapReportQuery()

  const [
    getRiskMapControlMeasurePlanReportQuery,
    { isLoading: getRiskMapControlMeasurePlanReportQueryIsLoading },
  ] = useLazyGetRiskMapControlMeasurePlanReportQuery()

  const [
    getRiskMapDangerReportQuery,
    { isLoading: getRiskMapDangerReportQueryIsLoading },
  ] = useLazyGetRiskMapDangerReportQuery()

  const [
    getRiskMapEmployeeReviewReportQuery,
    { isLoading: getRiskMapEmployeeReviewReportQueryIsLoading },
  ] = useLazyGetRiskMapEmployeeReviewReportQuery()

  const [
    getProfRiskManagementSystemReportQuery,
    { isLoading: getProfRiskManagementSystemReportQueryIsLoadign },
  ] = useLazyGetProfRiskManagementSystemReportQuery()

  const [getSizReportQuery, { isLoading: getSizReportQueryQueryIsLoadign }] =
    useLazyGetSizReportQuery()

  const transformFormValues = (form: FormValues): FormFilters => {
    return {
      company: hasCompany ? form.company?.['@id'] : meCompany?.['@id'],
      companyBranch: hasFilial
        ? form.filial?.['@id']
        : meCompanyBranch?.['@id'],
      branchSubdivision: form.division?.['@id'],
      jobPosition: form.position?.['@id'] || form.headPosition,
      riskAssessmentJobPosition: form.headPosition,
      reviewedBeforeAt: form.reviewedBeforeAt,
      reviewStatus: form.reviewStatus?.value
        ? ReviewStatusesToApi[form.reviewStatus?.value]
        : undefined,
      riskMap: form.riskMap?.['@id'],
      laborProtectionService: form.safetyDivision,
    }
  }

  return {
    transformFormValues,
    onSelectReport: setSelectedReport,
    filterModalFields,
    methods,
    items: [
      {
        title: DANGEROUS_REGISTRY,
        isLoading: getRiskMapDangerReportQueryIsLoading,
        onClick: getRiskMapDangerReportQuery,
        id: DANGEROUS_REGISTRY_REPORT,
      },
      {
        title: RISK_MANAGEMENT_PLAN,
        isLoading: getRiskMapControlMeasurePlanReportQueryIsLoading,
        onClick: getRiskMapControlMeasurePlanReportQuery,
        id: RISK_MANAGEMENT_PLAN_REPORT,
      },
      {
        title: ACK_WITH_RISK_MAPS,
        isLoading: getRiskMapEmployeeReviewReportQueryIsLoading,
        onClick: getRiskMapEmployeeReviewReportQuery,
        id: ACK_WITH_RISK_MAPS_REPORT,
      },
      {
        title: POSITIONS_WITHOUT_RISK_MAPS,
        isLoading: getPositionWithoutRiskMapReportQueryIsLoading,
        onClick: getPositionWithoutRiskMapReportQuery,
        id: POSITIONS_WITHOUT_RISK_MAPS_REPORT,
      },
      {
        title: RISK_MANAGEMENT_SYSTEM,
        isLoading: getProfRiskManagementSystemReportQueryIsLoadign,
        onClick: getProfRiskManagementSystemReportQuery,
        id: RISK_MANAGEMENT_SYSTEM_REPORT,
      },
      {
        title: SIZ,
        isLoading: getSizReportQueryQueryIsLoadign,
        onClick: getSizReportQuery,
        id: 'siz',
      },
    ],
  }
}

export default useRiskMapsReports
