import * as React from 'react'
import { FC } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import useFormProcessor from '@hooks/useFormProcessor'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { setSelectedProtectionMeansForms } from '@services/riskMapModal'
import { useAppSelector } from '@hooks/useAppSelector'
import { Option } from '@organisms/Select'
import AddButton from '@organisms/AddButton'
import { useLazyGetDangersSearchedProtectionMeansQuery } from '@services/protectionMeans'
import {
  ProtectionMeanConstructionOption,
  ProtectionMeanTypeOption,
  SearchedDangerProtectionMean,
} from '@models/protectionMeans'
import { convertMeanToForm } from '@utils/riskMap'
import {
  getCountName,
  getPeriodName,
  getUpdateBeforeName,
} from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields/ProtectionMeansFieldsBlock/nameUtils'
import { ProtectionMeanAdditionalType } from '@enums/ProtectionMeanAdditionalType'
import { AddButtonWrapper, BlocksWrapper } from './styled'
import ProtectionMeansFieldsBlock from './ProtectionMeansFieldsBlock'

export type ProtectionMeanItem = {
  type: ProtectionMeanTypeOption
  constructionMean: ProtectionMeanConstructionOption
  etnNumber: string | undefined
  quantity: string | undefined
  updateFrequency: string | undefined
  updateBeforeAt: string | undefined
  hasEqualConstructions: boolean
  src: SearchedDangerProtectionMean
  parentFormId?: string
  id: string
  additionalType: ProtectionMeanAdditionalType | null
}

type FormValues = {
  protectionMeans: ProtectionMeanItem[]
  prefilled: boolean
}

type Props = {
  dangerousIndex: number
  onModalClose: () => void
  event: Option
}

const ProtectionMeanFieldsContent: FC<Props & { methods: UseFormReturn }> = ({
  dangerousIndex,
  onModalClose,
  event,
  methods,
}) => {
  const [getDangersSearchedProtectionMeans] =
    useLazyGetDangersSearchedProtectionMeansQuery()
  const dispatch = useAppDispatch()
  const { control, getValues, setValue } = methods
  const { fields, append, remove } = useFieldArray({
    name: 'protectionMeans',
    control,
  })

  const handleSubmitForm = (form: FormValues) => {
    dispatch(
      setSelectedProtectionMeansForms({
        [dangerousIndex]: form,
      })
    )
    onModalClose()
  }

  const onDeleteProtectionMean = (item: ProtectionMeanItem) => {
    const { protectionMeans } = getValues() as {
      protectionMeans: ProtectionMeanItem[]
    }

    for (let i = protectionMeans.length - 1; i >= 0; i--) {
      const mean = protectionMeans[i]

      if (
        (item.src &&
          mean.src &&
          item.src?.['@id'] === mean.src?.parent?.['@id']) ||
        mean.parentFormId === item.id
      ) {
        console.warn('remove', mean, i)
        remove(i)
      }
    }
  }

  const onSelectConstruction = async (
    construction: ProtectionMeanConstructionOption | null,
    mean: ProtectionMeanItem,
    index: number
  ) => {
    onDeleteProtectionMean(mean)

    if (construction) {
      const { protectionMeans } = getValues() as {
        protectionMeans: ProtectionMeanItem[]
      }

      const currentMultilineField = protectionMeans[index]

      const currentMean = await getDangersSearchedProtectionMeans({
        'construction.type': currentMultilineField?.type?.['@id'],
        'construction.title': construction.title,
        event: event.value,
      })

      if (currentMean.data?.items.length === 1) {
        const mean = currentMean.data.items[0] || {}
        const converted = convertMeanToForm(mean)

        setValue(getCountName(index), converted.quantity)
        setValue(getPeriodName(index), converted.updateFrequency)
        setValue(getUpdateBeforeName(index), converted.updateBeforeAt)
      } else {
        console.error('Data for calculate not found!', { currentMean })
      }

      const result = await getDangersSearchedProtectionMeans({
        'construction.title': construction.title,
        event: event.value,
        eventProtectionMeanOnlyChilds: true,
      })

      const defaultMean = result.data?.items?.find((item) => item.defaultMean)

      if (defaultMean) {
        append(
          convertMeanToForm(defaultMean, {
            parentFormId: mean.id,
          })
        )
      } else {
        result.data?.items?.forEach((it) => {
          append(
            convertMeanToForm(it, {
              parentFormId: mean.id,
            })
          )
        })
      }
    }
  }

  return (
    <AddNewItemModalLayout
      handleCloseModal={onModalClose}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      customFullTitle="Средства индивидуальной защиты"
      withStopPropagation
    >
      <BlocksWrapper>
        {fields.map((field, index, array) => (
          <ProtectionMeansFieldsBlock
            field={field as unknown as ProtectionMeanItem}
            index={index}
            id={field.id}
            remove={remove}
            event={event}
            isLast={index === array.length - 1}
            key={field.id}
            onSelectConstruction={onSelectConstruction}
            onDeleteProtectionMean={onDeleteProtectionMean}
          />
        ))}
      </BlocksWrapper>
      <AddButtonWrapper>
        <AddButton onClick={() => append({ id: Math.random().toString() })}>
          Добавить СИЗ
        </AddButton>
      </AddButtonWrapper>
    </AddNewItemModalLayout>
  )
}

const ProtectionMeanFields: FC<Props> = (props) => {
  const defaultValues = useAppSelector(
    (state) =>
      state.riskMapModal.selectedProtectionMeansForms[props.dangerousIndex]
  )
  const methods = useFormProcessor({
    defaultValues,
  })
  return <ProtectionMeanFieldsContent {...props} methods={methods} />
}

export type { FormValues as ProtectionMeansFieldsFormValues }
export default ProtectionMeanFields
