import { useGetCoursesQuery } from '@services/courses'
import { PER_PAGE } from '@const/pagination'
import { createInitFilter } from '@utils/filters'
import { SortStates } from '@templates/ListPage/Filters/Sorter'
import useItemsFilters, { FilterState } from './useItemsFilters'
import useMe from './useMe'

type Params = {
  withoutFiltersByUser?: boolean
  initFilters?: FilterState
  pagination?: boolean
  limit?: number
  skip?: boolean
  initOrder?: { order: Record<string, SortStates> }
  forListener?: boolean
}

const useCourses = (params?: Params) => {
  const {
    initFilters = {},
    pagination = true,
    limit = PER_PAGE,
    skip,
    initOrder,
  } = params || {}
  const { id, isAdmin, onlyEmployee } = useMe()

  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    {
      ...initFilters,
      ...(isAdmin ? {} : createInitFilter('published', true)),
      ...(onlyEmployee ? createInitFilter('type', 'mini') : {}),
    },
    pagination,
    limit,
    initOrder
  )

  const state = useGetCoursesQuery(filters, {
    skip: skip || !id,
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  })

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useCourses
