import { UseFormReturn } from 'react-hook-form'
import useJobFilters from '@hooks/useJobFilters'
import useCoursesFilter from '@hooks/useCoursesFilter'
import { CURRENT_STATUS_FILTER } from '@const/filters'
import { FiltersProps } from '@templates/ListPage/Filters'
import { FilterTypes } from '@templates/ListPage/Filters/Filter'
import ACTIVE_FILTERS from '@const/activeFilters'

const useEmployeesFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial, division, position } = useJobFilters(methods)
  const courses = useCoursesFilter(
    'employeeEducationDestinations.educationDestination.course'
  )

  const filters: FiltersProps = [
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'reviewedRiskMapRevision',
    },
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'notReviewedRiskMapRevision',
    },
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'education',
      fieldNameForSubmit: 'employeeEducationDestinations.educationDestination',
    },
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'status',
      fieldNameForSubmit: 'employeeEducationDestinations.currentStatus',
    },
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    },
    courses,
    {
      filterType: FilterTypes.DATE,
      fieldName: 'nextBodyCheckDate',
      fieldNameForSubmit: 'nextBodyCheckDate[before]',
      placeholder: 'Пройти медосмотр до',
    },
  ]

  if (company) {
    filters.push({
      ...company,
      fieldNameForSubmit: 'companyBranch.company',
    })
  }

  if (filial) {
    filters.push({
      ...filial,
      fieldNameForSubmit: 'companyBranch',
    })
  }

  if (division) {
    filters.push({
      ...division,
      fieldNameForSubmit: 'subdivisionJobPositionRels.subdivision',
    })
  }

  if (position) {
    filters.push({
      ...position,
      fieldNameForSubmit: 'subdivisionJobPositionRels.jobPosition',
    })
  }

  filters.push(
    {
      filterType: FilterTypes.DATE,
      fieldName: 'passedAtAfter',
      placeholder: 'Пройден курс от',
      fieldNameForSubmit: 'employeeEducationDestinations.passedAt[after]',
    },
    {
      filterType: FilterTypes.DATE,
      fieldName: 'passedAtBefore',
      placeholder: 'Пройден курс до',
      fieldNameForSubmit: 'employeeEducationDestinations.passedAt[before]',
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'active',
      placeholder: 'Активность',
      withoutFetchOptions: true,
      options: ACTIVE_FILTERS,
      transformValue: (filter) => {
        if (filter.active?.[0]) {
          return {
            active: filter.active[0],
          }
        }

        if (typeof filter.active === 'boolean') {
          return {
            active: null,
          }
        }
      },
    },
    CURRENT_STATUS_FILTER
  )

  return filters
}

export default useEmployeesFilters
