import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div<{ hidden: boolean }>`
  ${({ hidden }) =>
    hidden
      ? ''
      : isMobile
      ? 'width: 100%; margin-bottom: 10px;'
      : 'max-width: 200px'}
`
